<template>
  <div
    v-if="loadDetail"
    :class="[loadDetail ? 'w3-block' : '']"
    class="w3-modal z-index-0"
  >
    <div class="w3-modal-content w3-animate-top relative">
      <div v-if="activeInvestment" class="absolute top-0 right-0 z-index-3">
        <a
          href="javascript:;"
          @click="closeInvestmentDetail"
          style="top: -10px"
          class="w3-button w3-red w3-hover-deep-orange hover-effect-tiny no-hover-underline w3-right"
        >
          x</a
        >
        <div style="clear: both"></div>
      </div>

      <InvestmentDetailPage
        :referral="referral"
        :investment="activeInvestment"
        :contactno="ContactNo"
        :email="Email"
        :fullname="Fullname"
        :phoneno="PhoneNo"
        :valuofinvestment="activeInvestment.ValuOfInvestment"
      ></InvestmentDetailPage>
    </div>
  </div>
  <div class="row equalHeightColumns">
    <div
      v-for="card in CardData"
      :key="card.FundCode"
      class="col-xl-4 col-md-6"
      @click="showInvestmentDetail(card)"
    >
      <section
        class="card w3-white mb-4 column w3-border cursor hover-effect w3-hover-light-grey w3-hover-border-red"
      >
        <div class="card-body bg-transparent m-0 pb-2">
          <div class="mt-0 mb-2 size13 bold pl-2 ellipsis">
            {{ card.FundName }} ({{ card.FundCode }})
          </div>
          <div class="w3-cell-row">
            <div class="w3-cell w3-cell-top">
              <i
                :class="[
                  card.GrowthIndex < 0 ? 'w3-text-red' : 'w3-text-green',
                ]"
                class="bx bx-line-chart size60"
                style="font-weight: 100 !important"
              ></i>
              <p
                class="size11 bold my-0"
                :class="[
                  card.GrowthIndex < 0 ? 'w3-text-red' : 'w3-text-green',
                ]"
              >
                Growth {{ formatCurrency(card.GrowthIndex) }}
                <i
                  class="bx bx-tada size18"
                  :class="[
                    card.GrowthIndex < 0
                      ? 'bx-down-arrow-alt'
                      : 'bx-up-arrow-alt',
                  ]"
                ></i>
              </p>
            </div>
            <div class="w3-cell w3-cell-top w3-right-align">
              <p class="mt-0 mb-1 size10 bold">Valu Of Investment</p>
              <p class="size14 bold mt-0 mb-3 w3-text-black">
                {{ formatCurrency(card.ValuOfInvestment, "&#8358;") }}
              </p>

              <p class="mt-0 mb-1 size10 bold">Total Invested</p>
              <p class="size14 bold my-0 w3-text-black">
                {{ formatCurrency(card.TotalAmountInvested, "&#8358;") }}
              </p>
            </div>
          </div>
          <div class="w3-cell-row pt-2 mt-3 w3-border-top">
            <div class="w3-cell w3-cell-top">
              <p class="mt-0 mb-1 size10">Units</p>
              <p class="size14 my-0 bold w3-text-black">
                {{ formatCurrency(card.FundUnit) }}
              </p>
            </div>
            <div class="w3-cell w3-cell-top w3-right-align">
              <p class="mt-0 mb-1 size11">Current Price</p>
              <p class="size14 my-0 bold w3-text-black">
                {{ formatCurrency(card.CurrentFundPrice, "&#8358;") }}
              </p>
            </div>
          </div>
        </div>
        <div class="px-2 py-2 size12 mt-1 w3-center w3-theme-d4">
          <span class="bx-flashingg"
            >CLICK FOR DETAIL, TOPUP AND LIQUIDATION</span
          >
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { formatCurrency } from "@/helpers/utilities";
import { useStore } from "vuex";
import { ref, computed, onBeforeMount } from "vue";
import InvestmentDetailPage from "@/components/InvestmentDetailPage";
export default {
  components: {
    InvestmentDetailPage,
  },
  props: ["referral"],
  setup() {
    const store = useStore();
    const DashBoard_Data = computed(() => store.state.DashBoard_Data);
    const fetching = computed(() => store.state.fetching.DashBoard_Data);
    const Fullname = computed(() => store.state.userData.Account_Name);
    const Email = computed(() => store.state.userData.EMail);
    const ContactNo = computed(() => store.state.userData.contactNo);
    const PhoneNo = computed(() => store.state.userData.PhoneNo);
    if (!(DashBoard_Data.value && DashBoard_Data.value.length)) {
      store.dispatch("Dashboard");
    }

    const SETTINGS = computed(() => store.state.appSettings);
    const CardData = computed(() => {
      const INV = [];
      for (let inv in DashBoard_Data.value) {
        if (
          DashBoard_Data.value[inv].FundCode !==
          SETTINGS.value._MURABABA_PLAN_CODE
        ) {
          INV.push(DashBoard_Data.value[inv]);
        }
      }
      return INV;
    });

    const activeInvestment = ref({});
    const loadDetail = ref(false);
    const showInvestmentDetail = (Investment) => {
      activeInvestment.value = Investment;
      loadDetail.value = true;
    };
    const closeInvestmentDetail = () => {
      loadDetail.value = false;
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      formatCurrency,
      CardData,
      fetching,
      activeInvestment,
      loadDetail,
      showInvestmentDetail,
      closeInvestmentDetail,
      Fullname,
      Email,
      PhoneNo,
      ContactNo,
    };
  },
};
</script>
<style scoped>
p {
  line-height: normal !important;
}
</style>
