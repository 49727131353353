<template>
  <div v-if="fetchingTrans" class="loading_spinner_box">
    <div class="loading_spinner"></div>
  </div>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>
    <div class="p-4">
      <div class="row">
        <div class="col-md-10 w3-border-right">
          <div
            v-if="newInvestment"
            :class="[newInvestment ? 'w3-block' : '']"
            class="w3-modal z-index-0"
          >
            <div class="w3-modal-content w3-animate-top relative">
              <a
                href="javascript:;"
                @click.prevent="newInvestment = false"
                style="top: -30px; right: -30px"
                class="w3-button w3-orange hover-effect-tiny w3-border no-hover-underline btn-rounded absolute right-10"
              >
                x</a
              >

              <NewInvestment
                callback="investments"
                :fullname="Fullname"
                :email="Email"
                :contactno="ContactNo"
                :phoneno="PhoneNo"
                @finished="newInvestment = false"
              />
            </div>
          </div>
          <div class="row equalHeightColumns pb-3 mb-4 w3-border-bottom">
            <div
              v-if="Balances"
              class="col-xl-4 col-md-6"
              style="min-width: 320px"
            >
              <InvestmentSummary
                :total="InvestmentTotal"
                :length="Balances.length"
                :classes="'column mb-4'"
              />
            </div>
            <div class="col-xl-4 col-md-6" style="min-width: 320px">
              <NewInvestmentButton
                v-if="!newInvestment"
                @click="newInvestment = true"
                :label="'ADD A NEW PORTFOLIO'"
              />
            </div>
          </div>
          <InvestmentsCard referral="investments" />

          <Transactions v-if="mounted" />
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</template>
<script>
import InvestmentSummary from "@/components/InvestmentSummary";
import NewInvestmentButton from "@/components/NewInvestmentButton";
import InvestmentsCard from "@/components/InvestmentsCard";
import Transactions from "@/components/Transactions";
import NewInvestment from "@/components/NewInvestment.vue";
import { ref, computed, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { equalHeightColumns, queryParameter } from "@/helpers/utilities";
import usePayments from "@/composables/use-payments";

export default {
  components: {
    InvestmentSummary,
    NewInvestmentButton,
    InvestmentsCard,
    Transactions,
    NewInvestment,
  },

  setup() {
    const store = useStore();
    const Fullname = computed(() => store.state.userData.Account_Name);
    const Email = computed(() => store.state.userData.EMail);
    const PhoneNo = computed(() => store.state.userData.PhoneNo);

    const ContactNo = computed(() => store.state.userData.contactNo);
    const DashBoard_Data = computed(() => store.state.DashBoard_Data);
    const fetching = computed(() => store.state.fetching.DashBoard_Data);

    if (!DashBoard_Data.value?.length) {
      store.dispatch("Dashboard");
    }
    const newInvestment = ref(false);
    const mounted = ref(false);
    const SETTINGS = computed(() => store.state.appSettings);

    const Balances = computed(() => {
      const INV = [];
      for (let inv in DashBoard_Data.value) {
        if (
          DashBoard_Data.value[inv].FundCode !==
          SETTINGS.value._MURABABA_PLAN_CODE
        ) {
          INV.push(DashBoard_Data.value[inv]);
        }
      }
      return INV;
    });

    const InvestmentTotal = computed(() => {
      let total = {
        ValuOfInvestment: 0,
        TotalAmountInvested: 0,
      };
      for (let inv in Balances.value) {
        total.ValuOfInvestment += parseFloat(
          Balances.value[inv].ValuOfInvestment
        );
        total.TotalAmountInvested += parseFloat(
          Balances.value[inv].TotalAmountInvested
        );
      }
      return total;
    });

    const title = ref("Investments");

    const retry = ref(1);
    const PaymentSuccess = (data) => {
      if (data.Trx.apiCalled !== 1) {
        if (retry.value <= 3) {
          retry.value++;
          Callback(trxref, retry.value);
        }
      }
    };
    const { IframeCallback: Callback, fetching: fetchingTrans } = usePayments(
      PaymentSuccess
    );
    const trxref = queryParameter().trxref;
    onBeforeMount(() => {
      if (trxref) {
        Callback(trxref, retry.value);
      }

      store.dispatch("resetIdleTime");
    });

    onMounted(() => {
      mounted.value = true;
      equalHeightColumns();
    });

    return {
      title,
      Balances,
      fetching,
      fetchingTrans,
      InvestmentTotal,
      newInvestment,
      mounted,
      Fullname,
      Email,
      ContactNo,
      PhoneNo,
    };
  },
};
</script>
