<template>
  <div class="row">
    <div class="col-12">
      <div v-if="1 === 2" class="w3-right-align mt-3 mb-2">
        <button
          @click.prevent="PrintStatement('investments')"
          class="w3-button w3-white w3-border py-2 width150"
          :disabled="fetchingStatement || !fundKey"
        >
          <span v-html="fetchingStatement ? spinner : 'Print Statement'"></span>
        </button>
      </div>
      <section class="card shadow w3-white mb-4">
        <div class="card-body m-0 bg-transparent">
          <div class="row mb-2">
            <div class="col-sm-5">
              <h4 class="bold">{{ fundKey }} Transactions</h4>
            </div>
            <div class="col-sm-7">
              <div class="w3-right mb-2 ml-2">
                <DateRangeFilter @gettransactions="getTransactions" />
              </div>
              <select
                v-model="fundKey"
                class="form-control width100 w3-right mb-2"
              >
                <option
                  v-for="(key, i) in productType"
                  :key="key"
                  :value="key"
                  :selected="i === 0"
                >
                  {{ key }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="fetching">
            <img class="mr-3" src="base/img/loading.gif" width="40" />
            Loading transactions
          </div>
          <div v-else>
            <div v-for="(key, i) in productType" :key="key">
              <div :class="[fundKey === key ? 'd-block' : 'd-none']">
                <TransactionsTable
                  :tableid="'table-' + key + '-' + i"
                  :headers="TableHeader"
                  :rows="TransactionsList[key]"
                  source="investments"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, onBeforeMount, ref } from "vue";
import TransactionsTable from "@/components/TransactionsTable";
import DateRangeFilter from "@/components/DateRangeFilter";
import usePrintStatement from "@/composables/use-print-statement";
export default {
  components: { TransactionsTable, DateRangeFilter },
  setup() {
    const store = useStore();
    const fetching = computed(() => store.state.fetching.Transactions);
    const productType = computed(() => store.state.InvestmentCodes);
    const ContactNo = computed(() => store.state.userData.contactNo);
    const fundKey = ref("");

    const activeRedemption = ref(productType.value[0]);
    const Period = ref({
      StartDate: "",
      EndDate: "",
    });
    const Transactions = computed(() => store.state.Transactions);
    if (!Transactions.value?.length) {
      store.dispatch("TransactionALLHistory", {
        StartDate: "",
        EndDate: "",
      });
    }
    const TransactionsList = computed(() => {
      const Trans = {};
      for (let trans in Transactions.value) {
        let FundCode = Transactions.value[trans].FundCode;

        if (typeof Trans[FundCode] === "undefined") {
          Trans[FundCode] = [];
        }
        Trans[FundCode].push(Transactions.value[trans]);
      }
      return Trans;
    });

    const TableHeader = ref([
      { text: "Value Date", value: "ValueDate" },
      { text: "Amount Invested", value: "AmountInvested" },
      { text: "Unit Price", value: "UnitPrice" },
      { text: "Description", value: "Description" },
      { text: "Fund Code", value: "FundCode" },
      { text: "Unit Quantity", value: "UnitQuantity" },
    ]);

    const getTransactions = (period) => {
      store.dispatch("TransactionALLHistory", {
        StartDate: period.StartDate,
        EndDate: period.EndDate,
      });
    };

    const Products = computed(() => store.state.Products);

    const ProductsArray = computed(() => {
      const pArray = {};
      for (let Product in Products.value) {
        let FundCode = Products.value[Product].FundCode;
        pArray[FundCode] = Products.value[Product];
      }
      return pArray;
    });
    const FundName = computed(
      () => ProductsArray.value[activeRedemption.value]
    );
    const { getStatement, fetchingStatement, spinner } = usePrintStatement();
    const PrintStatement = (type) =>
      getStatement(
        type,
        ContactNo.value,
        FundName.value.FundCode,
        FundName.value.InvestmentName
      );

    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      fundKey.value = productType.value[0];
    });
    return {
      TableHeader,
      Transactions,
      fetching,
      productType,
      getTransactions,
      activeRedemption,
      TransactionsList,
      Period,
      fundKey,
      PrintStatement,
      fetchingStatement,
      spinner,
    };
  },
};
</script>
